.container_loading {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-main);

  h4 {
    color: var(--dark-main);
    font-size: clamp(0.9rem, 0.94rem, 1rem);
    letter-spacing: 0.9px;
  }
  svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
  }
}
