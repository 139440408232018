.container_main {
  padding: 1.3rem 3rem 1.3rem 2rem;
  background-color: var(--bg-main);
}
.subtitle {
  color: var(--purple-main);
}

// Information
.ctnt_main {
  height: 100%;
  &__title {
    font-size: clamp(1.05rem, 1.15rem, 1.2rem);
  }
  &__description {
    margin-bottom: 1.9em;
    line-height: 21px;
    color: var(--light-black);
  }
}

/*  --------------------------------------------
  Documentación
--------------------------------------------*/
.content_doc_link {
  border: 1px solid var(--gray-color);
  background-color: #fff;
  box-shadow: var(--box-shadow-input);
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  a {
    margin: 0;
    padding: 0.7rem 0.8rem;
    font-size: clamp(0.8rem, 0.82rem, 0.84rem);
    font-weight: 400;
    text-decoration: wavy;
  }
}

/*  --------------------------------------------
  EDIT SDK
--------------------------------------------*/
.content_main_edition {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  .content_edit_main_colors {
    margin: 1rem 0;
  }
}

// Edit Text
.content_main_change_color_text {
  display: grid;
  margin: 1rem 0;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  .content_change_color_text {
    padding: 0.7rem;
    position: relative;
    border-radius: 4px;
    margin: 0rem 0.8rem 0.6rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(70, 70, 70, 0.137) 0px 8px 16px -8px;
    > p,
    > h4 {
      margin: 0;
    }
  }
}

// Edit Buttons
.content_main_change_color_btns {
  display: grid;
  align-items: center;
  margin: 1rem 0;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  .content_change_btns {
    padding: 1.2rem 0.95rem;
    border-radius: 4px;
    margin: 0rem 0.8rem 0.9rem 0;
    text-align: center;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(70, 70, 70, 0.137) 0px 8px 16px -8px;
    > p,
    > h4 {
      margin: 0;
    }
  }
}

.btn-style-general {
  border-width: 1px;
  border-style: solid;
  padding: 0.6rem 1rem;
  border-radius: 6px;
  width: 80%;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  position: relative;
}

// Configurations
.content_change_logo {
  background-color: white;
  padding: 1rem 0.9rem;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .custom_reset_file {
    margin-right: 10px;
    border-color: #c745c7;
    color: #c745c7;
    padding: 18px 0.6rem;
    display: flex;
    align-items: center;
    &:hover,
    &:active,
    &:focus {
      background-color: #c745c7;
      color: white;
    }
  }
  .custom_input_file {
    color: #fff;
    font-size: 0.78rem;
    text-align: center;
    padding: 0.6rem 0.9rem;
    background-color: var(--purple-main);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(70, 70, 70, 0.137) 0px 8px 16px -8px;
    cursor: pointer;
    border-radius: 4px;
    &:hover,
    &:active,
    &:focus {
      filter: brightness(120%);
    }
  }
}
